<template>
  <div class="agreement contanier">
    <h2>{{ title }}</h2>
    <div v-html="content"></div>
  </div>
</template>

<script>
import { httptext } from "../../api/userapi";
export default {
  name: "agreement",

  data() {
    return {
      type: "",
      title: "",
      content: ""
    };
  },
  created() {
    this.type = this.$route.query.type;
    if (this.type === "2") {
      this.gethttptext(this.type);
    } else if (this.type === "1") {
      this.gethttptext(this.type);
    }
  },
  mounted() {},

  methods: {
    gethttptext(id) {
      httptext({ article_id: id }).then(res => {
        this.content = res.list.content;
        this.title = res.list.name;
      });
    }
  }
};
</script>

<style lang="less" scoped>
h2 {
  font-size: 30px;
  color: #333333;
  text-align: center;
  margin-bottom: 30px;
}
.agreement {
  padding: 50px 0;
}
</style>
